<template>
    <scroll-container>
        <div class="skins-trade">
            <payment-order-info :order="order" :show-button="false" v-on="$listeners" />

            <template v-if="isTrading">
                <div class="skins-trade__steps">
                    <div class="skins-trade__step">
                        <div class="skins-trade__step-title">
                            <h2>1. {{ $t('pay.trade.steps.first.title') }}:</h2>
                        </div>

                        <div class="skins-trade__step-body">
                            <div class="skins-trade__data">
                                <div class="skins-trade__data-row">
                                    <p>
                                        <span>{{ $t('pay.trade.steps.first.trade_bot') }}</span>
                                    </p>

                                    <p>
                                        <a :href="`https://steamcommunity.com/profiles/${order.steam_trade_offer?.steam_bot?.steam_id?.toString() ?? ''}`" target="_blank" rel="noreferrer">
                                            <span>{{ order.steam_trade_offer?.steam_bot?.nickname }}</span>
                                        </a>
                                    </p>
                                </div>

                                <div class="skins-trade__data-row">
                                    <p>
                                        <span>{{ $t('pay.trade.steps.first.order_id') }}</span>
                                    </p>

                                    <p>
                                        <span>{{ order.id }}</span>
                                    </p>
                                </div>

                                <div class="skins-trade__data-buttons">
                                    <a :href="`https://steamcommunity.com/tradeoffer/${order.steam_trade_offer.id}`" target="_blank">
                                        <text-button full>
                                            {{ $t('pay.trade.steps.first.browser') }}
                                            <template #icon>
                                                <desktop-icon />
                                            </template>
                                        </text-button>
                                    </a>

                                    <a :href="`steam://openurl/https://steamcommunity.com/tradeoffer/${order.steam_trade_offer.id}`" target="_blank">
                                        <text-button theme="primary-outlined" full>
                                            {{ $t('pay.trade.steps.first.steam') }}
                                            <template #icon>
                                                <steam-icon />
                                            </template>
                                        </text-button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="skins-trade__step">
                        <div class="skins-trade__step-title">
                            <h2>2. {{ $t('pay.trade.steps.second.title') }}</h2>
                            <p>{{ $t('pay.trade.steps.second.description') }}</p>
                        </div>

                        <div class="skins-trade__step-body skins-trade__step-body_no-padding">
                            <div class="skins-trade__screen">
                                <img src="@/assets/images/steam-screen.png" alt="steam" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="skins-trade__reminder">
                    <div class="skins-trade__reminder-text">
                        <timer-icon />

                        <div>
                            <h2>{{ $t('pay.trade.reminder.title') }}</h2>

                            <p>{{ $t('pay.trade.reminder.description') }}</p>
                        </div>
                    </div>

                    <timer v-if="timeLeft" :value="timeLeft" @completed="timeout" />
                </div>
            </template>

            <div v-else class="skins-trade__result">
                <div :class="['skins-trade__result-icon', isDone ? 'skins-trade__result-icon_success' : 'skins-trade__result-icon_error']">
                    <check-circle-icon v-if="isDone" />
                    <danger-icon v-else />
                </div>

                <div class="skins-trade__result-title">
                    <template v-if="isDone">
                        <h1>{{ $t('pay.trade.result.done.title', { website: order.website.toUpperCase() }) }}</h1>

                        <p>{{ $t('pay.trade.result.done.description') }}</p>
                    </template>

                    <template v-else>
                        <h1>{{ $t('pay.trade.result.cancel.title', { website: order.website.toUpperCase() }) }}</h1>

                        <p>{{ $t('pay.trade.result.cancel.description') }}</p>
                    </template>
                </div>

                <a :href="`https://${order.website}`">
                    <text-button theme="secondary">
                        {{ $t('pay.trade.back') }}
                        <template #icon>
                            <return-arrow-icon />
                        </template>
                    </text-button>
                </a>
            </div>
        </div>
    </scroll-container>
</template>

<script>
    import { DateTime } from 'luxon';
    import { orderChannel, leaveOrderChannel, orderChannelEvents } from '@/echo/order';
    import { STATUS_DONE, STATUS_TIMEOUT, STATUS_WAIT_TRADE_OFFER } from '@/services/skinsPaymentApi/constants/orderStatus';
    import CheckCircleIcon from '@/components/icons/CheckCircleIcon';
    import DangerIcon from '@/components/icons/DangerIcon';
    import DesktopIcon from '@/components/icons/DesktopIcon';
    import PaymentOrderInfo from '@/components/pay/PaymentOrderInfo';
    import ReturnArrowIcon from '@/components/icons/ReturnArrowIcon';
    import ScrollContainer from '@/components/ScrollContainer';
    import SteamIcon from '@/components/icons/SteamIcon';
    import TextButton from '@/components/buttons/TextButton';
    import Timer from '@/components/Timer';
    import TimerIcon from '@/components/icons/TimerIcon';

    export default {
        name: 'SkinsTrade',
        components: {
            CheckCircleIcon,
            DangerIcon,
            DesktopIcon,
            PaymentOrderInfo,
            ReturnArrowIcon,
            ScrollContainer,
            SteamIcon,
            TextButton,
            Timer,
            TimerIcon,
        },
        props: {
            order: {
                type: Object,
                required: true,
            },
        },
        computed: {
            isTrading() {
                return this.timeLeft && STATUS_WAIT_TRADE_OFFER === this.status;
            },
            isDone() {
                return STATUS_DONE === this.status;
            },
        },
        methods: {
            timeout() {
                this.status = STATUS_TIMEOUT;
            },
        },
        data() {
            return {
                status: this.order.status,
                timeLeft: parseInt(DateTime.fromISO(this.order.steam_trade_offer.expiration_date).diffNow('seconds').seconds),
            };
        },
        mounted() {
            orderChannel(this.order.id).listen(orderChannelEvents.TradeOfferStatusChangedEvent, event => (this.status = event.status));
        },
        beforeDestroy() {
            leaveOrderChannel(this.order.id);
        },
    };
</script>

<style lang="scss" scoped>
    .payment-order-info {
        margin-bottom: 1rem;
    }

    .skins-trade {
        width: 100%;
        max-width: 130rem;
        margin: 0 auto;
    }

    .skins-trade__steps {
        display: flex;
        flex-direction: column;
    }

    .skins-trade__step {
        margin-top: 0.2rem;
        border-radius: 0.8rem;
        background-color: #141926;
    }

    .skins-trade__step-title {
        padding: 1.5rem 1.5rem 0;

        h2 {
            color: #ffffff;
            font-size: 1.3rem;
            text-align: center;
        }

        p {
            margin-top: 0.7rem;
            color: #808ba7;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.2rem;
            line-height: 1.2;
            text-align: center;
        }
    }

    .skins-trade__step-body {
        margin-top: 1.5rem;
        padding: 0 1.5rem 1.5rem;
        overflow: hidden;

        &_no-padding {
            padding: 0 !important;
        }
    }

    .skins-trade__data-row {
        display: flex;
        height: 4.8rem;

        p {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 1.5rem;
            background-color: #192137;

            span {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:first-child {
                flex-shrink: 0;
                width: 12.5rem;
                border-radius: 1rem 0 0 1rem;
                background-color: #171f33;
                color: #808ba7;
                font-family: 'Gotham Book', sans-serif;
                font-weight: 325;
            }

            &:last-child {
                border-radius: 0 1rem 1rem 0;
                color: #dfe8ff;
                font-family: 'Gotham Bold', sans-serif;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        &:not(:first-child) {
            margin-top: 0.1rem;
        }

        &:first-child p:last-child {
            a {
                color: #b7e9bc;
                text-decoration: underline;

                &:hover {
                    color: #b7e9bc;
                    text-decoration: none;
                }
            }
        }
    }

    .skins-trade__data-buttons {
        margin-top: 1rem;

        a {
            display: block;

            &:not(:first-child) {
                margin-top: 0.5rem;
            }
        }
    }

    .skins-trade__screen {
        width: 23rem;
        height: 27rem;
        margin: 0 auto -2.1rem;
        border: 0.3rem solid #3760cb;
        border-bottom: none;
        border-radius: 2.5rem;
        box-shadow: 0 14.4rem 18.4rem rgba(55, 105, 203, 0.5);
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .skins-trade__reminder {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        padding: 1.5rem;
        border-radius: 0.8rem;
        background-color: #389976;

        ::v-deep {
            .timer {
                margin-top: 1rem;
            }
        }
    }

    .skins-trade__reminder-text {
        display: flex;

        svg {
            flex-shrink: 0;
            width: 3rem;
            height: 3rem;
            margin-right: 0.5rem;
            fill: #b7e9bc;
        }

        h2 {
            color: #ffffff;
            font-family: 'Gotham Bold', sans-serif;
            font-size: 1.4rem;
            font-weight: 400;
        }

        p {
            margin-top: 0.5rem;
            color: #e4fbed;
            font-size: 1.1rem;
            font-weight: 350;
            line-height: 1.2;
        }
    }

    .skins-trade__result {
        padding: 3rem 1.5rem 1.5rem;
        border-radius: 0.8rem;
        background-color: #141926;
        overflow: hidden;

        a {
            display: block;
            max-width: 30rem;
            margin: 0 auto;
        }

        .button {
            width: 100%;
        }
    }

    .skins-trade__result-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.6rem;
        height: 9.6rem;
        margin: 0 auto;

        svg {
            width: 2.6rem;
            height: 2.6rem;
            fill: #ffffff;
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6.5rem;
            height: 6.5rem;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        &_error {
            &:after {
                background-color: #dc5252;
                box-shadow: 0 0 10.4rem rgba(220, 82, 82, 0.8);
            }

            &:before {
                background-color: rgba(220, 82, 82, 0.09);
            }
        }

        &_success {
            &:after {
                background-color: #54cb6e;
                box-shadow: 0 0 10.4rem rgba(82, 220, 129, 0.8);
            }

            &:before {
                background-color: rgba(82, 220, 129, 0.09);
            }
        }
    }

    .skins-trade__result-title {
        display: inline-block;
        width: 100%;
        margin: 2rem 0;

        h1 {
            color: #ffffff;
            font-size: 1.4rem;
            font-weight: 350;
            text-align: center;
        }

        p {
            margin-top: 0.5rem;
            color: #a7b3d0;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.2rem;
            text-align: center;
        }
    }

    @media screen and (min-width: 35em) {
        .skins-trade__reminder {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            ::v-deep {
                .timer {
                    margin: 0 0 0 1.5rem;
                }
            }
        }
    }

    @media screen and (min-width: 48em) {
        .skins-trade__steps {
            flex-direction: row;
        }

        .skins-trade__step {
            width: 50%;
            margin: 0.1rem;
        }
    }

    @media screen and (min-width: 120em) {
        .payment-order-info {
            margin-bottom: 2.5rem;
        }

        .skins-trade__step-title {
            padding: 4rem 4rem 0;

            h2 {
                font-size: 2rem;
            }

            p {
                margin-top: 1rem;
                font-size: 1.4rem;
            }
        }

        .skins-trade__step-body {
            margin-top: 3rem;
            padding: 0 4rem 4rem;
        }

        .skins-trade__screen {
            width: 28rem;
            height: 32rem;
        }

        .skins-trade__data-buttons .button,
        .skins-trade__result .button {
            height: 6.2rem;
        }

        .skins-trade__data-row {
            height: 6.2rem;

            p {
                padding: 0 2rem;
                font-size: 1.5rem;

                &:first-child {
                    width: 15rem;
                }
            }
        }

        .skins-trade__data-buttons {
            margin-top: 1.5rem;

            a:not(:first-child) {
                margin-top: 1rem;
            }
        }

        .skins-trade__reminder {
            margin-top: 2.5rem;
            padding: 2.5rem;

            ::v-deep {
                .timer {
                    margin-left: 2rem;
                }
            }
        }

        .skins-trade__reminder-text {
            svg {
                width: 4rem;
                height: 4rem;
                margin-right: 1rem;
            }

            h2 {
                font-size: 1.8rem;
            }

            p {
                font-size: 1.4rem;
            }
        }

        .skins-trade__result {
            padding: 6.5rem;
        }

        .skins-trade__result-icon {
            width: 11.8rem;
            height: 11.8rem;

            svg {
                width: 3.2rem;
                height: 3.2rem;
            }

            &:after {
                width: 8rem;
                height: 8rem;
            }
        }

        .skins-trade__result-title {
            margin: 2.5rem 0 4rem;

            h1 {
                font-size: 2.4rem;
            }

            p {
                margin-top: 1rem;
                font-size: 1.6rem;
            }
        }
    }

    @media screen and (min-width: 86.25em) {
        .skins-trade__steps {
            flex-direction: row;
            margin: -0.1rem;
        }

        .skins-trade__step {
            width: 50%;
            margin: 0.1rem;
        }
    }
</style>
