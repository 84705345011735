<template>
    <validation-observer ref="observer" v-slot="{ handleSubmit, reset }" slim>
        <modal :title="$t('pay.modal.title')" @close="close">
            <form @submit.prevent="handleSubmit(save)">
                <validation-provider name="trade_url" rules="required|url" v-slot="{ errors, reset }" slim>
                    <input-group :errors="errors" :label="$t('pay.modal.form.trade_url.label')" required>
                        <template #description>
                            {{ $t('pay.modal.form.trade_url.description') }}
                            <span>(</span>
                            <a href="http://steamcommunity.com/my/tradeoffers/privacy" target="_blank" rel="noreferrer">
                                {{ $t('pay.modal.form.trade_url.link') }}
                            </a>
                            <span>)</span>
                        </template>

                        <input-component
                            v-model="tradeUrl"
                            :placeholder="$t('pay.modal.form.trade_url.placeholder')"
                            @input="reset"
                            @mousedown.stop
                        />
                    </input-group>
                </validation-provider>

                <text-button full>{{ $t('pay.modal.form.button') }}</text-button>
            </form>
        </modal>
    </validation-observer>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { tradeUrl } from '@/services/skinsPaymentApi';
    import responseProcessingMixin from '@/mixins/responseProcessingMixin';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';
    import Modal from '@/components/Modal';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'TradeUrlModal',
        components: {
            InputComponent,
            InputGroup,
            Modal,
            TextButton,
            ValidationObserver,
            ValidationProvider,
        },
        mixins: [responseProcessingMixin],
        props: {
            value: String,
        },
        methods: {
            close() {
                this.$refs.observer.reset();
                this.$emit('close');
            },
            save() {
                tradeUrl(this.$route.query.hash, this.tradeUrl)
                    .then(() => {
                        this.$emit('input', this.tradeUrl);
                        this.close();
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.observer));
            },
        },
        data() {
            return {
                tradeUrl: this.value,
            };
        },
    };
</script>
