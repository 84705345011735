<template>
    <div class="pay-cart">
        <div class="pay-cart__header">
            <cart-icon />

            <div class="pay-cart__info">
                <h1>{{ $t('pay.cart.title') }}</h1>

                <p v-html="$t('pay.cart.info', { count: skins.length, total: formatPrice(total) })" />
            </div>
        </div>

        <div class="pay-cart__body">
            <div v-if="isLimit" class="pay-cart__warning">
                <danger-icon />

                <div>
                    <h1>{{ $t('pay.cart.warning.title', { min: formatPrice(order.min_amount) }) }}</h1>

                    <p>{{ $t('pay.cart.warning.description') }}</p>
                </div>
            </div>

            <skins>
                <skin
                    v-for="skin in skins"
                    :key="skin.assetId"
                    :currency="order.currency"
                    :skin="skin"
                    @click="$emit('removeSkin', skin)"
                    is-active
                />
            </skins>

            <text-button v-if="!isLimit" theme="info" :loading="loading" :disabled="loading" @click="submit">
                {{ $t('pay.cart.button', { total: formatPrice(total) }) }}
            </text-button>
        </div>
    </div>
</template>

<script>
    import { formatPrice as formatPriceHelper } from '@/helpers/common';
    import CartIcon from '@/components/icons/CartIcon';
    import CrossIcon from '@/components/icons/CrossIcon';
    import DangerIcon from '@/components/icons/DangerIcon';
    import IconButton from '@/components/buttons/IconButton';
    import Skin from '@/components/pay/Skin';
    import Skins from '@/components/pay/Skins';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'PayCart',
        components: {
            CartIcon,
            CrossIcon,
            DangerIcon,
            IconButton,
            Skin,
            Skins,
            TextButton,
        },
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            order: {
                type: Object,
                required: true,
            },
            skins: {
                type: Array,
                required: true,
            },
        },
        computed: {
            isLimit() {
                return this.total < this.order.min_amount;
            },
            total() {
                return this.skins.reduce((sum, skin) => sum + skin.price[this.order.currency], 0);
            },
        },
        methods: {
            formatPrice(price) {
                return formatPriceHelper(price, this.order.currency);
            },
            submit() {
                if (!this.isLimit) {
                    this.$emit('submit');
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .pay-cart {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        height: 60rem;
        background-color: #131825;
        overflow: hidden;
    }

    .pay-cart__header {
        display: flex;
        align-items: center;
        padding: 1rem;
        background-color: #141a28;

        > svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
            fill: #353e54;
        }

        h1,
        p {
            font-family: 'Gotham Book', sans-serif;
            font-weight: 325;
        }

        h1 {
            margin-bottom: 0.4rem;
            color: #e5ecf9;
            font-size: 1.4rem;
        }

        p {
            color: #55667f;
            font-size: 1.1rem;

            ::v-deep span {
                color: #ffffff;
                font-family: 'Gotham Bold', sans-serif;
                font-weight: 400;

                &:last-child {
                    color: #5dd880;
                }
            }
        }

        .icon-button {
            margin-left: auto;
        }
    }

    .pay-cart__body {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        flex: 1;
        padding: 1rem;
        overflow: hidden;

        .button {
            flex-shrink: 0;
            margin-top: 1rem;
        }
    }

    .pay-cart__warning {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        background-color: rgba(250, 102, 102, 0.05);

        > svg {
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 1rem;
            fill: #fa6666;
        }

        h1 {
            color: #fa6666;
            font-size: 1.2rem;
            font-weight: 350;
            line-height: 1.2;
        }

        p {
            color: #7b5959;
            font-size: 1rem;
            font-weight: 350;
            line-height: 1.2;
        }
    }

    @media screen and (min-width: 30em) {
        .pay-cart__header,
        .pay-cart__body,
        .pay-cart__warning {
            padding: 1.5rem;
        }

        .pay-cart__body .button {
            margin-top: 1.5rem;
        }
    }

    @media screen and (min-width: 75em) {
        .pay-cart {
            flex: 0 1 auto;
            width: 38rem;
            height: auto;
            margin-top: 0 !important;
        }
    }

    @media screen and (min-width: 120em) {
        .pay-cart {
            width: 58rem;
        }

        .pay-cart__header,
        .pay-cart__warning {
            padding: 3rem;
        }

        .pay-cart__header {
            > svg {
                width: 3.2rem;
                height: 3.2rem;
                margin-right: 1.5rem;
            }

            h1 {
                font-size: 2.1rem;
            }

            p {
                font-size: 1.4rem;
            }
        }

        .pay-cart__body {
            padding: 2rem 3rem;
            background-color: #131825;

            .button {
                margin-top: 2rem;
            }
        }

        .pay-cart__warning {
            margin-bottom: 2rem;

            > svg {
                width: 3rem;
                height: 3rem;
            }

            h1 {
                font-size: 1.5rem;
            }

            p {
                font-size: 1.2rem;
            }
        }
    }
</style>
