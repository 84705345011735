<template>
    <app-layout :show-sidebar="false" @modalClosed="modalOpened = false" adjust-window-height>
        <loader v-if="loading" />

        <skins-trade v-else-if="isTrading" :order="order" />

        <skins-deposit v-else :order="order" @openModal="modalOpened = true" @trade="trade" />

        <template #modal>
            <trade-url-modal v-if="modalOpened" v-model="order.trade_url" @close="modalOpened = false" />
        </template>
    </app-layout>
</template>

<script>
    import { DateTime } from 'luxon';
    import { orderInfo } from '@/services/skinsPaymentApi';
    import { STATUS_DRAFT, STATUS_WAIT_TRADE_OFFER } from '@/services/skinsPaymentApi/constants/orderStatus';
    import AppLayout from '@/pages/layout/AppLayout';
    import Loader from '@/components/Loader';
    import SkinsDeposit from '@/components/pay/SkinsDeposit';
    import SkinsTrade from '@/components/pay/SkinsTrade';
    import TradeUrlModal from '@/components/pay/TradeUrlModal';

    export default {
        name: 'Pay',
        components: {
            AppLayout,
            Loader,
            SkinsDeposit,
            SkinsTrade,
            TradeUrlModal,
        },
        computed: {
            isTrading() {
                return STATUS_WAIT_TRADE_OFFER === this.order.status && DateTime.fromISO(this.order.steam_trade_offer.expiration_date) > DateTime.now();
            },
        },
        methods: {
            fetchOrderInfo() {
                return orderInfo(this.$route.query.hash)
                    .then(({ data }) => {
                        if (![STATUS_DRAFT, STATUS_WAIT_TRADE_OFFER].includes(data.status)) {
                            window.location = `https://${data.website}/?order_id=${data.id}`;
                            return;
                        }

                        this.loading = false;
                        this.order = data;

                        if (!data.trade_url) {
                            this.modalOpened = true;
                        }
                    })
                    .catch(() => (window.location = '/'));
            },
            trade(data) {
                this.order = { ...this.order, ...{"steam_trade_offer": data}, status: STATUS_WAIT_TRADE_OFFER };
            },
        },
        data() {
            return {
                loading: true,
                modalOpened: false,
                order: null,
            };
        },
        mounted() {
            this.$i18n.locale = 'en';
            this.fetchOrderInfo();
        },
        beforeRouteEnter(to, from, next) {
            !to.query.hash ? next({ name: 'page-not-found' }) : next();
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .app-layout__content,
        .app-layout__content-outer > .scroll-container,
        .app-layout__content-outer > .scroll-container > .scroll-container__content {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .app-layout__content-outer > .scroll-container > .scroll-container__content {
            flex: none;
        }
    }

    @media screen and (min-width: 75em) {
        ::v-deep {
            .app-layout__content {
                height: 100%;
            }
        }
    }
</style>
